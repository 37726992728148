var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.show,"variant":"primary","opacity":"0.85","blur":"2","rounded":"sm"}},[_c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Adı","label-for":"account-username"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-live","placeholder":"Adı","state":errors.length > 0 ? false : null,"name":"username"},model:{value:(_vm.addAddressRequestModel.title),callback:function ($$v) {_vm.$set(_vm.addAddressRequestModel, "title", $$v)},expression:"addAddressRequestModel.title"}})]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Kısa","label-for":"account-name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"name","state":errors.length > 0 ? false : null,"placeholder":"Kısa"},model:{value:(_vm.addAddressRequestModel.address2),callback:function ($$v) {_vm.$set(_vm.addAddressRequestModel, "address2", $$v)},expression:"addAddressRequestModel.address2"}})]}}])})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Şehir","label-for":"account-name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"name","state":errors.length > 0 ? false : null,"placeholder":"Şehir"},model:{value:(_vm.addAddressRequestModel.town),callback:function ($$v) {_vm.$set(_vm.addAddressRequestModel, "town", $$v)},expression:"addAddressRequestModel.town"}})]}}])})],1)],1),_c('b-col',{attrs:{"sm":"8"}},[_c('b-form-group',{attrs:{"label":"Adres","label-for":"account-name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"name","state":errors.length > 0 ? false : null,"placeholder":"Adres"},model:{value:(_vm.addAddressRequestModel.address),callback:function ($$v) {_vm.$set(_vm.addAddressRequestModel, "address", $$v)},expression:"addAddressRequestModel.address"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.savePerson($event)}}},[_vm._v(" Kaydet ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_vm._v(" Temizle ")])],1)],1)],1)],1),_c('br'),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"striped":true,"bordered":true,"hover":true,"small":true,"items":_vm.persons,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by.sync":"id","show-empty":"","empty-text":"Kayıt bulunamadı.","head-variant":"dark"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }